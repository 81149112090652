import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; // Import a11y styles
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: '#a5c638',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} // Show one slide on all mobile devices
      slidesPerGroup={1}
      breakpoints={{
        // When window width is >= 640px
        640: {
          slidesPerView: 2, // Show two slides on smaller desktop screens
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, // Show one slide on medium screens (md)
          slidesPerGroup: 1,
        },
        // When window width is >= 1024px (desktop lg)
        1024: {
          slidesPerView: 3, // Show three slides on desktop (lg)
          slidesPerGroup: 2,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5'>
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://d1bvpoagx8hqbg.cloudfront.net/259/02ed91e4b11c290f4b46a126ab1c503e.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Cedric Oakhaven</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[5%] pb-5 pr-1 pl-1'>
            Facing a threatening blackmailer who knew about my past, and continuously extorted money under threats of harming my family, I felt trapped. Discovering Retrieve Digital Crypto online was a turning point. After explaining my situation, they swiftly intervened and managed to bring the blackmailer down. Thanks to their expertise, I finally found relief from the distressing ordeal.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-5'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-5'>Detroit, USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://d1bvpoagx8hqbg.cloudfront.net/259/3537e5005120061c82bcb0bf06b2858c.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Dorian Riverwind</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[5%] pb-5 pr-1 pl-1'>
            Being targeted by online threats that endangered my family due to private information known to the perpetrator was an alarming experience. In desperation, I stumbled upon Retrieve Digital Crypto online. After explaining the situation, their prompt assistance neutralized the threats. Thanks to their effective intervention, the online threats were eliminated, and my family's safety was secured.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-5'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-5'>Louisiana, USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://media.licdn.com/dms/image/D5603AQEWy_eA8cRBDg/profile-displayphoto-shrink_800_800/0/1668222792200?e=2147483647&v=beta&t=WIdqzdJbltPr7TRNb-b-RhAkYB4PXLiNris4LRdoLAk' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Beatrix Rosebloom</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[6%] pb-5 pr-1 pl-1'>
            As a public figure, I faced a harrowing experience when blackmailed with compromising photos, leading to exorbitant payments totaling $67,000. Despite my efforts, the blackmailer persisted. Enter Retrieve Digital Crypto. Their intervention not only restored my lost funds but also brought the blackmailer to justice, putting an end to the torment I faced.
              </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-10'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-10'>California ,Canada 🇺🇸  </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://i.redd.it/d4kvb086d8za1.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Evangeline Moonbeam</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[5%] pb-5 pr-1 pl-1'>
            Haunted by past mistakes, I found myself blackmailed by an ex-partner who threatened to ruin my marriage with intimate photos I'd shared years ago. Desperate and cornered, I sought help from Retrieve Digital Crypto. Their intervention not only retrieved the money I'd spent, but also put an end to the ex's threats, allowing me to protect my marriage and find peace.
              </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-5'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-5'>Ontario, Canada 🇨🇦  </p>
          </div>
        </div>
      </SwiperSlide>
      
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://i.redd.it/p24fdqvk6gpb1.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Freya Riverwind</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[5%] pb-5 pr-1 pl-1'>
            Facing a distressing situation, I found myself being blackmailed by my own brother, who threatened to expose damaging secrets and videos that could shame me and my family. His relentless extortion for money became unbearable. That's when I turned to Retrieve Digital Crypto. Their intervention was a lifeline, putting an end to the blackmail and offering me the relief I needed to protect my family's dignity and peace.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-1'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-1'>Sydney, Australia 🇦🇺  </p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MySwiper;

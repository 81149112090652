import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; // Import a11y styles
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: '#a5c638',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} // Show one slide on all mobile devices
      slidesPerGroup={1}
      breakpoints={{
        // When window width is >= 640px
        640: {
          slidesPerView: 2, // Show two slides on smaller desktop screens
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, // Show one slide on medium screens (md)
          slidesPerGroup: 1,
        },
        // When window width is >= 1024px (desktop lg)
        1024: {
          slidesPerView: 3, // Show three slides on desktop (lg)
          slidesPerGroup: 2,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5'>
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://photos2.spareroom.co.uk/images/flatshare/listings/large/78/70/78700646.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Merrick Jenkins</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[12%] pb-5 pr-1 pl-1'>
            I was scammed $70,000 from a fake investment website and spent months unsuccessfully trying to reclaim my money. Fortunately, I encountered Retrieve Digital Crypto. Following an upfront payment, their team of experts efficiently restored my funds, saving me from a prolonged and fruitless endeavor.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-10'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-10'>San Antonio, USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://i.redd.it/4ax68zh42qf61.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Sterling Fraser</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[13%] pb-5 pr-1 pl-1'>
            I mistakenly sent my Bitcoins to a fake recovery firm in hopes of retrieving my lost assets. After a long struggle to get my funds back, I found solace in Retrieve Digital Crypto. With their expert guidance and a minimal upfront payment, they efficiently recovered my lost Bitcoin, saving me from further losses.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-10'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-10'>Atlanta, GA USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://pbs.twimg.com/ext_tw_video_thumb/1617985738276212736/pu/img/7Tt81twFGap49GGN.jpg:large' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Cassian Radcliffe</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[13%] pb-5 pr-1 pl-1'>
            After sending my coins to the wrong address by mistake, I faced a grim situation. Retrieve Digital Crypto came to my rescue. Their skilled team, after an initial payment, swiftly recovered my lost coins, sparing me the distress and anguish of the wrong transaction.
              </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-10'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-10'>San Diego, GA USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://qph.cf2.quoracdn.net/main-qimg-a224fb9136bcd47d2d72536f5f174fd9-lq' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Saskia Beckett</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[13%] pb-5 pr-1 pl-1'>
            Falling victim to a phishing attack, I lost a considerable amount of Bitcoin. Unable to recover it on my own, I sought the help of Retrieve Digital Crypto. With their proficiency and an initial payment, they swiftly restored my lost funds, offering a light at the end of the dark tunnel caused by the scam.
              </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-10'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-10'>Florida, USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://qph.cf2.quoracdn.net/main-qimg-f114b5d24d572f5718ff848a11b74441-lq' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Orlaith Pembroke </p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[13%] pb-5 pr-1 pl-1'>
            Having fallen victim to a scam disguised as an investment opportunity, I lost a substantial amount. Despite failed attempts to reclaim my assets, Retrieve Digital Crypto turned the tide. With their expertise and a nominal upfront fee, they efficiently restored what I had lost, providing a beacon of hope in a dark situation.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-10'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-10'>Wales, UK 🇬🇧  </p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MySwiper;

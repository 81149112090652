import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; // Import a11y styles
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: '#a5c638',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} // Show one slide on all mobile devices
      slidesPerGroup={1}
      breakpoints={{
        // When window width is >= 640px
        640: {
          slidesPerView: 2, // Show two slides on smaller desktop screens
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, // Show one slide on medium screens (md)
          slidesPerGroup: 1,
        },
        // When window width is >= 1024px (desktop lg)
        1024: {
          slidesPerView: 3, // Show three slides on desktop (lg)
          slidesPerGroup: 2,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5'>
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://media.licdn.com/dms/image/D5603AQFR3Yv3zThKzQ/profile-displayphoto-shrink_800_800/0/1675771814485?e=2147483647&v=beta&t=tlcYWYZjSWm4pGX_rtQISYMMTYgSO0Jz1dWqiQzbmZ4' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Elijah Moon</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[12%] pb-5 pr-1 pl-1'>
            My social media account fell victim to hacking, leading to its misuse by a scammer. Desperate to regain control, I stumbled upon Retrieve Digital Crypto—a team of experts who swiftly and effectively assisted me in restoring my account with remarkable ease. Their professionalism and rapid action were truly commendable.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-10'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-10'>Mississippi, USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT86raCvrwYUUgRvZnsbGznjF_JgAzBvESMDw&usqp=CAU' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Caspian Wilder</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[4%] pb-5 pr-1 pl-1'>
            My social media account was hacked by a scammer who began soliciting money from my friends on Facebook, causing significant damage to my reputation. Feeling helpless, I was advised to turn to Retrieve Digital Crypto. Initially hesitant about their request for an upfront payment, I took the leap of faith, and to my relief, they efficiently restored my account without any delay.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-5'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-5'>Philadelphia, USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://i.redd.it/pc8uzdgk70k81.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Alex Fox</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[5%] pb-5 pr-1 pl-1'>
            My Instagram account, boasting 1.3 million followers, was suddenly disabled, causing immense distress as I struggled to recover it. Fortunately, a recommendation led me to Retrieve Digital Crypto. Despite my initial apprehension, I made an upfront payment, and without hesitation, they efficiently restored my Instagram account within just 2 days. I'm profoundly grateful for encountering this team of experts.
              </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-5'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-5'>San Antonio, USA 🇺🇸   </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://i.redd.it/ou8hqxlgxnjb1.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Imogen Bloom</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[17%] pb-5 pr-1 pl-1'>
            I had suspicions about my husband cheating, yet lacked concrete evidence. Upon hearing about a group of expert individuals, I sought their help to monitor his phone activity. Thanks to their assistance, I obtained the evidence I needed to confirm his infidelity. Grateful to this team for rescuing me from a false relationship.
              </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-10'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-10'>Oak Villie, Canada 🇨🇦  </p>
          </div>
        </div>
      </SwiperSlide>
      
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://preview.redd.it/all-day-at-home-today-netflix-selfie-hope-you-are-doing-v0-wbcr1cuyy4pa1.png?width=640&crop=smart&auto=webp&s=91d5f7a1f43ae05342c151b3c718026ddd1cce67' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Allegra Nightingale</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[5%] pb-5 pr-1 pl-1'>
            I suspected an employee of siphoning money from my business. Despite receiving a salary that couldn't justify her sudden expensive purchases, coupled with noticeable losses in my business's capital, I was unsure how to proceed. A friend recommended Retrieve Digital Crypto. After an initial payment, their team conducted surveillance on her phone. The evidence they provided conclusively proved her involvement in stealing from my business. Grateful to this team for safeguarding my financial interests.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-2'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-2'>South  Wales, UK 🇬🇧  </p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MySwiper;

import React from 'react';
import Navbar from './Navbar';
import {motion } from "framer-motion";
import Circlexx from "./hooks/Circlexx";
import Circlex from "./hooks/Circlex";
import Circle from "./hooks/Circle";
import { BsCheck2All } from "react-icons/bs";
import { Link } from 'react-router-dom';
import Footer from "./hooks/Footer";
import AccountReview from  "./hooks/AccountReview";
import Progress from "./hooks/Progress";




const Account = () => {
    const CustomeIcon = {
        fontSize: "25px",
        '--custom-icon-color': '#a5c638'
    }
    
  return (
   <>
   <Progress/>
    <div className="box1 overflow-hidden">
            <section class="Account">
              <div className='relative'>
                <Navbar/>
              </div>
            
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
               
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-5 pt-[5%]'>
                            <div className='bg-[reen] h-fit overflow-hidden pt-[20%]'>
                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.3, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-white  pt-10'>Home <i class="ri-arrow-right-s-line text-[#a5c638]"></i> 
                                    Service <i class="ri-arrow-right-s-line text-[#a5c638]"></i></p>
                                </motion.div>
                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-[2.1rem] xl:text-[2.7rem] font-bold text-white'>Account <br />Activation/Unlock</p>
                                </motion.div>
                                
                            </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <div className='bg-[#efefef] h-fit'>
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                    <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-5 pt-[2%]'>
                        <div className='b-green-600 h-fit'>
                            <motion.p
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.4, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                          Enduring the inconvenience of losing access to crucial systems can disrupt our daily routines and hinder our 
                          progress. In our esteemed organization, we recognize the urgency and importance of this issue, motivating us 
                          to prioritize our exceptional Retrieve Digital Crypto service. Whether you require access to your personal 
                          profiles for legitimate reasons or assistance in reclaiming devices within legal boundaries, our dedicated 
                          team is prepared to provide unwavering support throughout the entire process. With a robust arsenal of resources 
                          and cutting-edge tools at our disposal, we possess the expertise to overcome even the most intricate account 
                          challenges.
                            </motion.p> <br /> 
                            <motion.p
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.5, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                            Rest assured, no platform presents a puzzle too daunting for us to tackle. With our profound proficiency 
                            and unwavering commitment to excellence, we adeptly navigate obstacles, ensuring swift and reliable access 
                            to your critical profiles. Don't allow account obstacles to impede your progress. Place your trust in our 
                            capabilities and empower yourself to regain control over your digital domain.
                            </motion.p>
                        </div>
                        <div className='bg-green-00 h-fit'>
                            <div className='flex justify-center'>
                                <img src="https://images.unsplash.com/photo-1551434678-e076c223a692?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" 
                                className='rounded-md'/>
                            </div>
                        </div>

                       
                    </div>
                </div>

               
            </div>

            <div className='mx-auto overflow-hidden  md:max-w-7xl '>
                    <div className='pt-5 ml-3'>
                        <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className=''>Top Services on  Account <span className='top__b'>Unlock/Activation</span> </span></p>
                    </div>
                    <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[2%]'>
                        <div className='bg-geen-600 h-fit mt-0 xl:mt-10'>
                            <div className='flex justify-center'>
                                <div className='bg-ed-600 h-fit w-full'>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Activation of Deactivated Account</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Recliam Funds From Suspended or Locked Bank Account</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Suspended Social Media Account Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Activation Of suspended Social Media Account</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-range-600 h-fit w-full xl:block hidden'>
                                <div className='flex '>
                                <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Activation of Locked Crypto wallet</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='bg-range-600 h-fit w-full xl:hidden block'>
                                <div className='flex '>
                                      
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Activation of Locked Crypto wallet</p>
                                        </div>
                                    </div>
                                    {/* <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Activation Of suspended Social Media Account</p>
                                        </div>
                                    </div> */}
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circle/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlex/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlexx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Success</p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className='b-green-600 h-fit'>
                        <div className='bg-geen-600 h-fit'>
                    <motion.div className="bg-[geen] h-fit shadow-lg pl-2 pr-2 pb-2 pt-1 rounded-lg  right__border bg-white"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.4 }}
                    transition={{ delay: 0.2, duration: 0.4 }}
                    variants={{
                        hidden: { opacity: 0, x: 50 },
                        visible: { opacity: 1, x: 0 },
                    }}>
                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.3, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                        <p>
                        For dependable account unlock and recovery, entrust Retrieve Digital Crypto Experts. With our mastery, personalized assistance, and platform policy proficiency, we expertly navigate the path to help you regain access to your priceless accounts. Choose Retrieve Digital Crypto Experts as your unwavering ally in this crucial journey.
                        </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.4, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#a5c638]'/>
                            </div>
                            <p className='pt-2 pl-4'>
                                <span className='font-bold'>Account Recovery Mastery:</span> At Retrieve Digital Crypto Experts, our seasoned team comprehends the intricacies of account unlock and recovery with unrivaled expertise. With our mastery, we adeptly guide you through the steps to regain access to your invaluable accounts.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.5, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#a5c638]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Tailored Assistance:</span> We provide personalized, hands-on support to address your unique challenges in account unlock and recovery. Our dedicated team works closely with you, ensuring a seamless and efficient recovery process.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.6, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#a5c638]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Comprehensive Platform Coverage:</span> From Instagram, Facebook, Twitter, TikTok, to various other social platforms, our proficiency spans the spectrum. We adeptly navigate the complexities of diverse platforms, ensuring successful retrieval.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.7, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#a5c638]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Platform Policy Proficiency:</span>  
                                Retrieve Digital Crypto Experts boasts an in-depth understanding of various platform policies, streamlining the process and enabling us to unlock and recover your accounts with utmost efficiency and security.
                            </p>
                        </motion.div>

                        
                    </motion.div>
                    </div>
                        </div>
                    </div>
            </div>



                <div className='bg-[#efefef] h-fit'>
                    <div className='pt-5 mx-auto overflow-hidden  md:max-w-7xl ' >
                        <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className=''>Top <span className='top__b'>Testimonies</span> </span></p>


                        <div className='pb-[12%]'>
                            <AccountReview/>
                        </div>
                        
                    </div>
                </div>



                <section class="hero">
                    <div class="hero-content">
                        <h2 className="text-center">Need our professional services?</h2>
                        <p className="text-center">Contact us now to get started!</p>
                        <Link to="/Contact" class="contact-btn">Contact Us</Link>
                    </div>
                </section>


                <div>
                <Footer/>
            </div>
      </div>
   
   
   
   
   
   
   </>
  )
}

export default Account;
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import Crypto from './Components/Crypto';
import Romance from "./Components/Romance";
import SocialMedia from "./Components/SocialMedia";
import Blackmail from "./Components/Blackmail";
import Phone from "./Components/Phone";
import Account from "./Components/Account";
import About from './Components/About';
import Contact from "./Components/Contact";
function App() {
  return (
   <Routes>
      <Route path='/Navbar' element={<Navbar/>}/>
      <Route path='/' element={<Home/>}/>
      <Route path='/Crypto' element={<Crypto/>}/>
      <Route path='/Romance' element={<Romance/>}/>
      <Route path='/SocialMedia' element={<SocialMedia/>}/>
      <Route path='/Blackmail' element={<Blackmail/>}/>
      <Route path='/Phone' element={<Phone/>}/>
      <Route path='/Account' element={<Account/>}/>
      <Route path='/About' element={<About/>}/>
      <Route path='/Contact' element={<Contact/>}/>
   </Routes>
  );
}

export default App;
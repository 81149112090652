import React from 'react';
import Navbar from './Navbar';
import {motion } from "framer-motion";
import Circlexx from "./hooks/Circlexx";
import Circlex from "./hooks/Circlex";
import Circle from "./hooks/Circle";
import { BsCheck2All } from "react-icons/bs";
import { Link } from 'react-router-dom';
import Footer from "./hooks/Footer";
import BlackmailReview from "./hooks/BlackmailReview";
import Progress from "./hooks/Progress";




const Blackmail = () => {
    const CustomeIcon = {
        fontSize: "25px",
        '--custom-icon-color': '#a5c638'
    }
    
  return (
   <>
   <Progress/>
    <div className="box1 overflow-hidden">
            <section class="Blackmail">
              <div className='relative'>
                <Navbar/>
              </div>
            
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
               
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-5 pt-[5%]'>
                            <div className='bg-[reen] h-fit overflow-hidden pt-[20%]'>
                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.3, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-white  pt-10'>Home <i class="ri-arrow-right-s-line text-[#a5c638]"></i> 
                                    Service <i class="ri-arrow-right-s-line text-[#a5c638]"></i></p>
                                </motion.div>
                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-[2.1rem] xl:text-[2.7rem] font-bold text-white'>Help From <br/>Blackmail/Extortion</p>
                                </motion.div>
                                
                            </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <div className='bg-[#efefef] h-fit'>
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                    <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-5 pt-[2%]'>
                        <div className='b-green-600 h-fit'>
                            <motion.p
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.4, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                           Retrive Digital Crypto is a staunch advocate for countering the insidious acts of blackmail and extortion. 
                           Our mission revolves around extending a compassionate hand to individuals ensnared in the distressing grip 
                           of these malicious practices. We empathize with the emotional and psychological turmoil that victims often 
                           endure when confronted by threats and manipulation inherent in blackmail and extortion situations. 
                           Through our unwavering dedication, we aim to empower and support these individuals on their journey 
                           towards recovery and justice.
                            </motion.p> <br /> 
                            <motion.p
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.5, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                            If you or someone you know is grappling with the harrowing effects of blackmail or extortion, we encourage you 
                            to take a step towards reclaiming your life. Reach out to us at Retrive Digital Crypto, where our team of 
                            experts is steadfastly committed to providing guidance, resources, and tailored solutions to navigate these 
                            challenges. Through our comprehensive approach, we aspire to dismantle the power dynamics wielded by those 
                            who perpetrate blackmail and extortion. At Retrive Digital Crypto, we stand resolute in our mission to foster 
                            a safer environment and a brighter future for all those affected by these harmful actions.
                            </motion.p>
                        </div>
                        <div className='bg-green-00 h-fit'>
                            <div className='flex justify-center'>
                                <img src="https://st3.depositphotos.com/14474202/18073/i/450/depositphotos_180736732-stock-photo-worried-man-looking-smart-phone.jpg" alt="" 
                                className='rounded-md'/>
                            </div>
                        </div>

                       
                    </div>
                </div>

               
            </div>

            <div className='mx-auto overflow-hidden  md:max-w-7xl '>
                    <div className='pt-5 ml-3'>
                        <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className=''>Top Services on Blackmail /<span className='top__b'>Extortion</span> </span></p>
                    </div>
                    <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[2%]'>
                        <div className='bg-geen-600 h-fit mt-0 xl:mt-10'>
                            <div className='flex justify-center'>
                                <div className='bg-ed-600 h-fit w-full'>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Money Recovery From Blackmail/Extortion</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Help from Online Threats</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Sextortion Help</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Extortion help</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-range-600 h-fit w-full xl:block hidden'>
                                <div className='flex '>
                                <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Help from Blackmail</p>
                                        </div>
                                    </div>
                                    {/* <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Sim Card Cloning</p>
                                        </div>
                                    </div> */}
                                    {/* <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Text Messages Hack/Spy</p>
                                        </div>
                                    </div> */}
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='bg-range-600 h-fit w-full xl:hidden block'>
                                <div className='flex '>
                                      
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Help from Blackmail</p>
                                        </div>
                                    </div>
                                    {/* <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Sim Card Cloning</p>
                                        </div>
                                    </div> */}
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circle/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlex/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlexx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Success</p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className='b-green-600 h-fit'>
                        <div className='bg-geen-600 h-fit'>
                    <motion.div className="bg-[geen] h-fit shadow-lg pl-2 pr-2 pb-2 pt-1 rounded-lg  right__border bg-white"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.4 }}
                    transition={{ delay: 0.2, duration: 0.4 }}
                    variants={{
                        hidden: { opacity: 0, x: 50 },
                        visible: { opacity: 1, x: 0 },
                    }}>
                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.3, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                        <p>
                        With Retrive Digital Crypto leading the way and our dedicated team by your side, we stand as your unwavering ally in breaking free from the grip of blackmail. Our steadfast commitment to excellence, paired with our team's profound understanding of the complexities of recovery, sets us apart. Here's why choosing us is the optimal decision for you and your assets:
                        </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.4, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#a5c638]'/>
                            </div>
                            <p className='pt-2 pl-4'>
                                <span className='font-bold'>Expertise in Complex Recovery:</span> With extensive expertise in navigating intricate recovery scenarios, we stand ready to guide you through the complexities of blackmail and extortion recovery. Trust our seasoned team to lead the way.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.5, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#a5c638]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Proven Success:</span> With a track record of successful recoveries, they demonstrate a history of efficiently reclaiming lost funds for clients.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.6, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#a5c638]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Confidentiality and Trust:</span> Trust is the cornerstone of our relationship. Count on us to handle your case with the utmost confidentiality and discretion, ensuring your peace of mind throughout the recovery process.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.7, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#a5c638]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Holistic Security Solutions:</span>  
                                Our commitment extends beyond recovery, encompassing safeguarding your future. Explore our comprehensive security measures designed to prevent future threats and protect your assets.
                            </p>
                        </motion.div>

                        
                    </motion.div>
                    </div>
                        </div>
                    </div>
                </div>



                <div className='bg-[#efefef] h-fit'>
                    <div className='pt-5 mx-auto overflow-hidden  md:max-w-7xl ' >
                        <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className=''>Top <span className='top__b'>Testimonies</span> </span></p>


                        <div className='pb-[12%]'>
                            <BlackmailReview/>
                        </div>
                        
                    </div>
                </div>



                <section class="hero">
                    <div class="hero-content">
                        <h2 className="text-center">Need our professional services?</h2>
                        <p className="text-center">Contact us now to get started!</p>
                        <Link to="/Contact" class="contact-btn">Contact Us</Link>
                    </div>
                </section>


                <div>
                <Footer/>
            </div>
     </div>
   
   </>
  )
}

export default Blackmail;
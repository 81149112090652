import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; // Import a11y styles
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: '#a5c638',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} // Show one slide on all mobile devices
      slidesPerGroup={1}
      breakpoints={{
        // When window width is >= 640px
        640: {
          slidesPerView: 2, // Show two slides on smaller desktop screens
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, // Show one slide on medium screens (md)
          slidesPerGroup: 1,
        },
        // When window width is >= 1024px (desktop lg)
        1024: {
          slidesPerView: 3, // Show three slides on desktop (lg)
          slidesPerGroup: 2,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5'>
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://cdn.hashnode.com/res/hashnode/image/upload/v1650457166334/xvsZ92smo.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>August Riverwood</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[5%] pb-5 pr-1 pl-1'>
            As a satisfied customer of Retrieve Digital Crypto, I sought their help to unlock my phone. Their efficiency and expertise in unlocking services were impressive. The team's approach and dedication to resolving my issue were exceptional, ensuring a smooth and quick resolution. I am grateful for their assistance and professionalism, making my experience with them outstanding.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-5'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-5'>Florida, USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://media-cdn.tripadvisor.com/media/photo-m/1280/21/b8/e1/c3/jakewright1994.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Wilder Moonbeam</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[13%] pb-5 pr-1 pl-1'>
            I was stuck with an iCloud-locked iPhone that I couldn't access or use. Retrieve Digital Crypto stepped in and efficiently unlocked my device. Their expertise in iCloud unlocking was a game-changer, allowing me full access to my device without any hassle. I'm incredibly grateful for their prompt and effective service.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-10'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-10'>California, GA USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://www.alicesarc.org/wp-content/uploads/2021/05/will-rutt1614617353.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Arlo Grey</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[6%] pb-5 pr-1 pl-1'>
            A scammer was preying on me, and I was desperate to track them down. Retrieve Digital Crypto used their expertise to spy on the scammer's phone, gathering crucial information to locate and bring the scammer to justice. Their efficient espionage service proved invaluable, ensuring the scammer was held accountable for their actions
              </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-10'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-10'>Northern Ireland, UK 🇬🇧  </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://i.cuteonly.com/photos/41/1/8w/nice-girl-anastasia-from-russia-163450-1.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Briar Rowanthorn</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[5%] pb-5 pr-1 pl-1'>
            I found myself locked out of my iCloud account and all my iOS devices. Retrieve Digital Crypto swiftly intervened, initiating a logout from all devices. Their seamless process ensured my account security, enabling a hassle-free re-entry into my iCloud account. Their expertise in iCloud security was truly remarkable.
              </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-10'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-10'>New South Wales,Australia  🇦🇺  </p>
          </div>
        </div>
      </SwiperSlide>
      
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://image.winudf.com/v2/image1/Y29tLmVjaHRlY2guZ2lybHNzZWxmaWVfc2NyZWVuXzVfMTU0ODU1Mjc3Nl8wMDc/screen-5.webp?fakeurl=1&type=.webp' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Lila Elias</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[13%] pb-5 pr-1 pl-1'>
            Suspecting infidelity, I sought Retrieve Digital Crypto's help to spy on my husband's phone. Their expertise in discreetly monitoring his device unveiled the truth. Their thorough investigation revealed the cheating, offering me the closure and evidence I needed to address the situation. Their professionalism and discretion were beyond comparison.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-10'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-10'>Illinois, USA 🇺🇸   </p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MySwiper;

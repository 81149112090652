import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; // Import a11y styles
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: '#a5c638',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} // Show one slide on all mobile devices
      slidesPerGroup={1}
      breakpoints={{
        // When window width is >= 640px
        640: {
          slidesPerView: 2, // Show two slides on smaller desktop screens
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, // Show one slide on medium screens (md)
          slidesPerGroup: 1,
        },
        // When window width is >= 1024px (desktop lg)
        1024: {
          slidesPerView: 3, // Show three slides on desktop (lg)
          slidesPerGroup: 2,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5'>
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://d2mjvz2lqjkhe7.cloudfront.net/as/assets-mem-com/cmi/7/1/1/0/10200117/20210519_154225277_0_orig.jpg/-/billy-mcintyre-cartersville-ga-obituary.jpg?a.balancewhite=true&maxheight=650' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Beauregard Lexington</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[12%] pb-5 pr-1 pl-1'>
            After investing over $54,000 on a scam website, I was unable to withdraw my profits. However, Retrieve Digital Crypto swiftly restored my funds and took down the fraudulent website. Their expertise saved my investment and put an end to the scam, providing both financial recovery and peace of mind.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-10'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-10'>Iowa, USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://qph.cf2.quoracdn.net/main-qimg-e3450ba8034ee6eda93b2ea7e32bb70c-lq' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Embethia Calloway.</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[10%] pb-5 pr-1 pl-1'>
            After falling victim to a $78,000 online romance scam, I struggled for months to recover my money. Upon discovering Retrieve Digital Crypto and making an upfront payment, they effortlessly restored my funds. This team is truly the best, swiftly recovering my money and reinstating a sense of security after such a deceitful act.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-5'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-5'>Memphis, USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://static3.machteamsoft.ro/thumbnails_high/profile/226/13251226_4562401287crop_2393.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>C. Nicolas</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[10%] pb-5 pr-1 pl-1'>
            Initially investing $30,000 in Bitcoin for what turned out to be a scam, I struggled for months to recover my hard-earned money. Hearing about Retrieve Digital Crypto, I made an upfront payment and within two days, their expert team magically restored my funds. Their swift action and efficiency were truly impressive
              </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-10'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-10'>Arizona, USA 🇺🇸   </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://preview.redd.it/25-years-old-girl-selfie-v0-p2ubsn5r1rcb1.jpg?auto=webp&s=475be4532ee764eb911d6a0bef823da6a6cd81c2' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Imogen Bloom</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[8%] pb-5 pr-1 pl-1'>
            I had suspicions about my husband cheating, yet lacked concrete evidence. Upon learning about a group of expert individuals, I sought their help to monitor his phone activity. Thanks to their assistance, I obtained the evidence I needed to confirm his infidelity. Grateful to this team for providing the means to uncover the truth in my relationship.
              </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-5'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-5'>Mississauga, Canada 🇨🇦  </p>
          </div>
        </div>
      </SwiperSlide>
      
      <SwiperSlide>
        <div className='bg-[white] rounded-md border__B'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://preview.redd.it/feeling-cute-today-25-v0-wiakf8p37nfa1.jpg?width=640&crop=smart&auto=webp&s=0649f21deb04ef527926227ae95eb15bf84985e5' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[black] font-bold pt-2'>Ondine Fairweather</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[black] text-center pt-[5%] pb-5 pr-1 pl-1'>
            Enduring years of blackmail by my ex-husband, threatening my reputation, led to monthly payments of $12,000. Exhausted and seeking help, I turned to Retrieve Digital Crypto. With an upfront payment, they took down my ex-husband and restored every penny he'd taken. Their swift action brought an end to the torment, providing both financial recovery and peace of mind, allowing me to safeguard my reputation.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-2'>
           <ion-icon name="location-outline" size="medium" color="#a5c638"></ion-icon>
           </div>
            <p className='text-[black] pt-2'>California, Usa 🇺🇸   </p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MySwiper;

import React from 'react';
import { FaUser, FaFlag, FaComment, FaEnvelope } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import Footer from './hooks/Footer';
import Navbar from './Navbar';
import { motion } from 'framer-motion';
import Progress from "./hooks/Progress";

const LostDogForm = () => {
  
  const {
    register,
    trigger,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const isValid = await trigger();
    if (!isValid) {
      // Prevent form submission if there are errors
      return;
    }
    // Handle form submission with valid data
    console.log('Form data:', data);
  };

  

  return (
    <>
    <Progress/>
    <div>
      
      <section class="Contact">
              <div className='relative'>
                <Navbar/>
              </div>
            
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
               
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-5 pt-[5%]'>
                            <div className='bg-[reen] h-fit overflow-hidden pt-[20%]'>
                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.3, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-white  pt-10'>Home <i class="ri-arrow-right-s-line text-[#a5c638]"></i> 
                                    Service <i class="ri-arrow-right-s-line text-[#a5c638]"></i></p>
                                </motion.div>
                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-[2.1rem] xl:text-[2.7rem] font-bold text-white'>Contact Us <br /> Today</p>
                                </motion.div>
                                
                            </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
      
        <div>
        
          
          
          <div className="bg-[#f6f6f6] shadow-md pb-10 pt-5 ">
            <div className="bg-[reen] mx-auto max-w-7xl overflow-hidden rounded-md petFom__inputs">
              <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-[1%] xl:pt-[3%] pt-[2%]">
                <div className="bg-[reen] h-fit">
                  <form
                    action="https://formsubmit.co/92a0115f747862f5879fe77990348ad8"
                    onSubmit={onSubmit}
                    method="POST"
                    className="petFom__inputs"
                  >
                    <div className="flex justify-center pt-10 gap-3">
                      <p className="font-bold text-[2rem] text-center pt-2 text-[black]">Contact Us</p>
                      <div>
                        <img src="phonex.png" alt="" className="w-[64px]" />
                      </div>
                    </div>

                    <div className="mx-auto max-w-2xl p-6">
                      <div className="grid gap-4">
                        <div className="relative">
                          <input
                            type="text"
                            placeholder="Name"
                            className="pl-10 pr-4 py-2 w-full border border-[#a5c638] rounded-lg focus:outline-none focus:border-[#a5c638]"
                            {...register('Name', {
                              required: 'Your Name Is Required.',
                              maxLength: {
                                value: 100,
                                message: 'Name should not exceed 100 characters.',
                              },
                            })}
                          />
                          <FaUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" color="#a5c638" />
                        </div>
                        {errors.Name && (
                          <div className="flex justify-center mt-2">
                            <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 text-white mb-2">
                              {errors.Name.message}
                            </p>
                          </div>
                        )}

                        <div className="relative">
                          <input
                            type="email"
                            placeholder="Email"
                            className="pl-10 pr-4 py-2 w-full border border-[#a5c638] rounded-lg focus:outline-none focus:border-[#a5c638]"
                            {...register('email', {
                              required: 'Your Email Is Required.',
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Invalid email address.',
                              },
                            })}
                          />
                          <FaEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" color="#a5c638" />
                        </div>
                        {errors.email && (
                          <div className="flex justify-center mt-2">
                            <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 mb-2 text-white">
                              {errors.email.message}
                            </p>
                          </div>
                        )}

                        <div className="relative">
                          <input
                            type="text"
                            placeholder="Country"
                            className="pl-10 pr-4 py-2 w-full border border-[#a5c638] rounded-lg focus:outline-none focus:[#a5c638]"
                            {...register('Country', {
                              required: 'Please Enter Country.',
                              maxLength: {
                                value: 100,
                                message: 'Country should not exceed 100 characters.',
                              },
                            })}
                          />
                          <FaFlag className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" color='#a5c638' />
                        </div>
                        {errors.Country && (
                          <div className='flex  justify-center mt-2'>
                            <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 mb-2 text-white ">
                              {errors.Country.message}
                            </p>
                          </div>
                        )}

<select
  id="my-select"
  {...register("selection", {
    required: 'Please select a service', // Updated error message
  })}
  className='border border-gray-300 rounded pl-2 pr-5 py-2 w-full focus:outline-none focus:ring-2 text-black focus:border-[#a5c638] mb-5'
>
  <option value="" className='text-[gray]'>
    Select Service
  </option>
  <option value="Restore Lost Crypto">Restore Lost Crypto</option>
  <option value="Romance Scam Recovery">Romance Scam Recovery</option>
  <option value="Bitcoin Recovery">Bitcoin Recovery</option>
  <option value="Wallet Tracking">Wallet Tracking</option>
  <option value="Spy">Spying</option>
  <option value="Social Media Hack">Social Media hack/Recovery</option>
  <option value="Icloud Unlock">Icloud Unlock</option>
  <option value="Account Activation">Account Activation</option>
  <option value="BlackMail/Extortion">BlackMail/Extortion</option>
  <option value="USDT Recovery/Hack">USDT Recovery/Hack</option>
  <option value="Whatsapp Hack/Spy">Whatsapp Hack/Spy</option>
  <option value="Gmail Recovery/Hack">Gmail Recovery/Hack</option>
  <option value="Password Recovery">Password Recovery</option>
  <option value="Background Check Removal">Background Check Removal</option>
  <option value="Tracking">Tracking</option>
  <option value="Phoen Unlock">Phoen Unlock</option>
  <option value="Narcissist Victims Support Health Line">Narcissist Victims Support Health Line</option>
</select>
{errors.selection && ( // Updated error reference
  <div className='flex justify-center mt-2'>
    <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 mb-2 text-white ">
      {errors.selection.message} {/* Updated error message */}
    </p>
  </div>
)}


                        <div className="relative">
                          <textarea
                            placeholder="Message"
                            className="pl-10 pr-4 py-2 h-40 resize-none w-full border border-[#a5c638] rounded-lg focus:outline-none focus:border-[#a5c638]"
                            rows={4}
                            {...register('Message', {
                              required: 'Message is Required',
                              maxLength: {
                                value: 2000,
                                message: 'Message should not exceed 2000 characters.',
                              },
                            })}
                          />
                          <FaComment className="absolute left-3 top-5 transform -translate-y-1/2 text-gray-500" color='#a5c638' />
                        </div>
                        {errors.Message && (
                          <div className='flex  justify-center mt-2'>
                            <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 text-white mb-3 ">
                              {errors.Message.message}
                            </p>
                          </div>
                        )}

                      </div>

                     
                      <div className='mt-3'>
                        <button className='bg-[#a5c638] hover:bg-[#a5c638] text-white px-4 py-2 rounded cursor-pointer' type='submit'>
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="bg-[reen] h-fit">
                  <div className='flex justify-center mt-0 xl:mt-[13%]'>
                  <iframe
      title="Google Map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.985453283443!2d-0.08483812337958073!3d51.513482871814695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876034d6b89e435%3A0x8790f1ed372227bc!2sLeadenhall%20St%2C%20London%20EC3V%204AB%2C%20UK!5e0!3m2!1sen!2sng!4v1702308359889!5m2!1sen!2sng"
      width="600"
      height="450"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
     
    </div>
    </>
  );
};

export default LostDogForm;
import React, {useState} from 'react';
import Navbar from './Navbar';
import { motion } from 'framer-motion';
import Avatar from '@mui/material/Avatar';
import { useRef, useEffect } from 'react';
import Circlexx from "./hooks/Circlexx";
import Circlex from "./hooks/Circlex";
import Circle from "./hooks/Circle";
import { BsCheck2All } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { useInView, useMotionValue, useSpring } from 'framer-motion';
import  Review  from './hooks/Review';
import Footer from './hooks/Footer';
import Progress from "./hooks/Progress";


const Home = () => {

    const AnimatedNumbers = ({ value }) => {
        const ref = useRef(null);
        const motionValue = useMotionValue(0);
        const springValue = useSpring(motionValue, { duration: 6000 });
        const isInview = useInView(ref);
    
        useEffect(() => {
            if (isInview) {
                motionValue.set(value);
            }
        }, [isInview, value, motionValue]);
    
        useEffect(() => {
            springValue.on("change", (latest) => {
                if (ref.current && latest.toFixed(0) <= value) {
                    ref.current.textContent = parseInt(latest).toLocaleString(); // Add this line to format the value with commas
                }
            });
        }, [springValue, value]);
    
        return <span ref={ref}></span>;
    };

   
    const Accordion = ({ title, content }) => {
        const [isOpen, setIsOpen] = useState(false);
      
        return (
          <div className="border rounded shadow mb-4">
            <div
              className="flex justify-between items-center p-4 cursor-pointer bg-gray-100"
              onClick={() => setIsOpen(!isOpen)}
            >
              <h2 className="text-lg font-semibold">{title}</h2>
              <svg
                className={`w-6 h-6 transition-transform duration-300 transform text-[#a5c638] ${
                  isOpen ? 'rotate-45' : ''
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d={isOpen ? 'M6 18L18 6' : 'M6 18L18 6M6 6l12 12'}
                />
              </svg>
            </div>
            {isOpen && (
              <div className="p-4 bg-white">
                <p>{content}</p> <br />
              </div>
            )}
          </div>
        );
      };
    
  return (
    <>
    <Progress/>
    <div className="box1 overflow-hidden">
            <section class="herox">
              <div className='relative'>
                <Navbar/>
              </div>
            
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                <motion.div className='pt-0 xl:ml-0 ml-10 flex'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.3, duration: 0.5 }}
                 variants={{
                     hidden: { opacity: 0, x: -50 },
                     visible: { opacity: 1, x: 0 },
                 }}>
                   
                        <div className="pl-2 relative">
                           {/* <img src="revoke.png" alt="" className="w-[40%]"/> */}
                        </div>
                </motion.div>
               
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                            <div className='bg-[reen] h-fit overflow-hidden pt-[20%]'>
                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.3, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-white'>Your Trusted Partner for Recovering Lost Funds from Scams!</p>
                                </motion.div>
                                <motion.div
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <p className='text-[2.1rem] xl:text-[2.5rem] font-bold text-white'>Combining Expertise and  Advanced Technology for Fast Retrieval</p>
                                </motion.div>

                                <motion.div className='pt-2'
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.7, duration: 0.5 }}
                                 variants={{
                                     hidden: { opacity: 0, x: -50 },
                                     visible: { opacity: 1, x: 0 },
                                 }}>
                                    <div>
                                   
                                 </div>
                                    <p className='text-white'>
                                    We specialize in professional fund recovery services for individuals and businesses, guaranteeing you 
                                    a quick and effortless process for reclaiming your assets.
                                    </p>
                                </motion.div>
                                 <div className='pt-5 flex gap-3'>
                                    <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true, amount: 0.5 }}
                                    transition={{ delay: 0.5, duration: 0.5 }}              
                                    variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                    }}>
                                        <Avatar className='!bg-[#a5c638]'>
                                            <a href="https://wa.me/message/DHP3GD7QJWQDO1">
                                            <i class="ri-whatsapp-line"></i>
                                            </a>
                                        </Avatar>
                                    </motion.div>
                                    <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true, amount: 0.5 }}
                                    transition={{ delay: 0.7, duration: 0.5 }}              
                                    variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                    }}>
                                        <Avatar className='!bg-[#a5c638]'>
                                            <a href="mailto:retrievedigitalcrypto@gmail.com">
                                                <i class="ri-mail-open-line"></i>
                                            </a>
                                        </Avatar>
                                    </motion.div>
                                    <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true, amount: 0.5 }}
                                    transition={{ delay: 0.8, duration: 0.5 }}              
                                    variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                    }}>
                                        <Avatar className='!bg-[#a5c638]'>
                                            <a href="https://t.me/Retrievedigitalcrypto">
                                                <i class="ri-telegram-line"></i>
                                            </a>
                                            </Avatar>
                                    </motion.div>
                                    <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true, amount: 0.5 }}
                                    transition={{ delay: 0.8, duration: 0.5 }}              
                                    variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                    }}>
                                        <Avatar className='!bg-[#a5c638]'>
                                            <a href="https://www.instagram.com/retrievedigitalcrypto?igsh=MXg5a2lrdHJ4bnc2Yw%3D%3D&utm_source=qr">
                                            <i class="ri-instagram-line"></i>
                                            </a>
                                            </Avatar>
                                    </motion.div>
                                    <motion.div
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true, amount: 0.5 }}
                                    transition={{ delay: 0.8, duration: 0.5 }}              
                                    variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                    }}>
                                        <Avatar className='!bg-[#a5c638]'>
                                            <a href="https://www.tiktok.com/@retrieve.digital?_t=8jOexXZK07Z&_r=1">
                                                <i class="ri-tiktok-line"></i>
                                            </a>
                                            </Avatar>
                                    </motion.div>
                                 </div>
                                <div className='flex'>
                                <div className='bg-[reen] h-fit flex gap-1 mr-1 mt-5'>
                                   <a href="/Contact"><button className='get__one'>Report Scam <i class="ri-arrow-right-line"></i></button></a>
                                </div>
                                <div className='bg-[reen] h-fit flex gap-1 mr-1 mt-5'>
                                    <a href="https://retrievedigitalcrypto.com/signup" target='_blank' rel='noopener noreferrer'>
                                        <button className='get__onex text-black'>Sign-Up <i class="ri-arrow-right-line"></i></button>
                                     </a>
                                </div>

                                </div>
                            </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
      </div>

        <div className='bg-[#e0e0e0]'>
            <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                        <div className='pt-5 ml-3'>
                            <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className='top__b'>Our Top Policy</span></p>
                        </div>
                <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[3%]'>
                    <motion.div className='bg-reen-600 h-fit shadow__x bg-white'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.3, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                        <div className='pl-5 pt-5 pb-6'>
                         <div className='bg-[#d8f37e] w-fit pl-2 pt-2 pb-2 pr-2 rounded-md'>
                            <img src="money-back.png" alt="" className='w-[60px]'/>
                        </div>
                        </div>
                        <div className='pl-5'>
                            <p className='!font-bold text-[1.2rem]'>Refund Ploicy</p>
                        </div>

                            <div className='pl-5 pt-2 pr-3'>
                                <p className='pb-5'>
                                    With a wealth of expertise at our disposal, we maintain a high success rate, minimizing refund requests. 
                                      If you're unsatisfied, rest assured that we offer a swift and hassle-free refund process.
                                </p>
                            </div>
                    </motion.div>
                    <motion.div className='bg-reen-600 h-fit shadow__x bg-white'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='pl-5 pt-5 pb-6'>
                         <div className='bg-[#d8f37e] w-fit pl-2 pt-2 pb-2 pr-2 rounded-md'>
                            <img src="resilience.png" alt="" className='w-[60px]'/>
                        </div>
                        </div>
                        <div className='pl-5'>
                            <p className='!font-bold text-[1.2rem]'>Resilience</p>
                        </div>

                            <div className='pl-5 pt-2 pr-3'>
                                <p className='pb-5'>
                                Within our recovery agency, we epitomize the fundamental trait of resilience. Our dedication to aiding clients in demanding situations remains resolute. With a comprehensive spectrum of skills.
                                </p>
                            </div>
                    </motion.div>
                    <motion.div className='bg-reen-600 h-fit shadow__x bg-white'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.5, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='pl-5 pt-5 pb-1'>
                         <div className='bg-[#d8f37e] w-fit pl-2 pt-2 pb-2 pr-2 rounded-md'>
                            <img src="analytics.png" alt="" className='w-[60px]'/>
                        </div>
                        </div>
                        <div className='pl-5'>
                            <p className='!font-bold text-[1.2rem]'>Accuracy</p>
                        </div>

                            <div className='pl-5 pt-1 pr-3'>
                                <p className='pb-5'>
                                Unearth the pure excellence of our incredibly adaptable and ever-changing group, overflowing with proficiency across a multitude of fields. Observe the effortless harmony as we unite to skillfully organize and achieve impeccable results.
                                </p>
                            </div>
                    </motion.div>
             </div>
            </div>


            <div className='mx-auto overflow-hidden  md:max-w-full mt-10'>
                <div className='Numbers'>
                    <div className='mx-auto overflow-hidden  md:max-w-7xl relative'>
                        <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[3%]'>
                                <div className='bg-green-00 h-fit'>
                                    <div>
                                        <p className='font-bold text-[1.5rem] text-white '> <span className='top__b'>About Us</span></p>
                                    </div>

                                    <div className='pt-5'>
                                        <p className='text-[25px] font-bold text-white'>Pioneering Advanced Technological <br />
                                         <span className='text-[#a5c638]'>Solutions Delivery</span> </p>
                                    </div>

                                    <div><br />

                                        <p className='text-[#dfdcdc]'>
                                        Technology facilitates the advancement and exchange of information. The fundamental goal of information technology (IT) 
                                        is to optimize operations through inventive multitasking. At Retrieve digital crypto, our adept team leverages these 
                                        resources to effectively and openly tackle cybersecurity hurdles.
                                        </p>
                                    </div>

                                    <div className='mt-5 Abt_left'>
                                      <a href="/About"><button className='About__btn'>See More <i class="ri-arrow-right-line "></i></button></a>  
                                    </div>

                                </div>
                                <div className='bg-geen-600 h-fit xl:pt-10 pt-0'>
                                    <div className='flex gap-3'>
                                        <div className='bg-white h-fit w-full rounded-md'style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}>
                                                <div className='flex justify-center pt-5'>
                                                    <p className='font-bold text-[1.8rem] text-[#a5c638]'>$<AnimatedNumbers value={31}/>m+</p> 
                                                </div>
                                                <div className='flex justify-center text-center pb-5'>
                                                    <p className='font-bold text-[1.4rem] text-white'>Money Restored</p>
                                                </div>
                                            </div>
                                            <div className='bg-white h-fit w-full rounded-md' style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}>
                                            <div className='flex justify-center pt-5'>
                                                    <p className='font-bold text-[1.8rem] text-[#a5c638]'><AnimatedNumbers value={12000}/>+</p> 
                                                </div>
                                                <div className='flex justify-center text-center pb-5'>
                                                    <p className='font-bold text-[1.4rem] text-white'>Relied Upon By</p>
                                                </div>
                                            </div>
                                    </div>
                                    
                                        <div className='flex gap-3 mt-3'>
                                        <div className='bg-white h-fit w-full rounded-md'style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}>
                                                <div className='flex justify-center pt-5'>
                                                    <p className='font-bold text-[1.8rem] text-[#a5c638]'><AnimatedNumbers value={10000}/>+</p> 
                                                </div>
                                                <div className='flex justify-center text-center pb-5'>
                                                    <p className='font-bold text-[1.4rem] text-white'>Satisfied Clients</p>
                                                </div>
                                            </div>
                                            <div className='bg-white h-fit w-full rounded-md' style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}>
                                            <div className='flex justify-center pt-5'>
                                                    <p className='font-bold text-[1.8rem] text-[#a5c638]'><AnimatedNumbers value={9000}/>+</p> 
                                                </div>
                                                <div className='flex justify-center text-center pb-5'>
                                                    <p className='font-bold text-[1.4rem] text-white'>Secured Clients</p>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                        </div>
                    </div>
                </div>  
            </div>
            
            <div className='bg-white'>
                    <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                        <div className='pt-5 ml-3'>
                            <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className='top__b'>Our Top Services</span></p>
                        </div>
                        <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-3  xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-2 gap-4 pb-10 pt-[3%]'>

                            <div className="max-w-md mx-auto overflow-hidden shadow-lg relative bg-[#ecebeb] rounded-lg border-4 border-transparent transition-transform ">
                                <div className="relative overflow-hidden aspect-w-16 aspect-h-9 transition-transform hover:scale-105">
                                     <img
                                        src="https://www.baymgmtgroup.com/wp-content/uploads/2022/05/shutterstock_2031084629.jpg"
                                        alt=""
                                        className="object-cover cursor-pointer"
                                    />
                                </div>
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center transition-transform hover:scale-125">
                                    
                                </div>
                                    <div className="text-start mt-4 pl-3">
                                        <p className='font-bold text-[1.2rem]'>Crypto Currency Recovery</p>
                                    </div> 
                                    <div className='pl-3 mt-2 pr-1'>
                                        <p>
                                            Encountered the distress of losing funds or cryptocurrency? Fear not, our team at Retrieve digital crypto 
                                            stands ready to aid in their recovery. Benefit from our expertise to reclaim access to your 
                                            inaccessible wallet, courtesy of the dedicated professionals.
                                        </p>
                                    </div> 
                                    <div className='mt-5 Abt_left pb-2 ml-3'>
                                      <a href="/Crypto"><button className='About__btnx'>See More <i class="ri-arrow-right-line "></i></button></a> 
                                    </div> 
                            </div>  

                            <div className="max-w-md mx-auto overflow-hidden shadow-lg relative bg-[#ecebeb] rounded-lg border-4 border-transparent transition-transform ">
                                <div className="relative overflow-hidden aspect-w-16 aspect-h-9 transition-transform hover:scale-105">
                                     <img
                                        src="https://leb.fbi.gov/image-repository/profile-of-female-crying-stock.jpg/@@images/image"
                                        alt=""
                                        className="object-cover cursor-pointer"
                                    />
                                </div>
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center transition-transform hover:scale-125">
                                    
                                </div>
                                    <div className="text-start mt-4 pl-3">
                                        <p className='font-bold text-[1.2rem]'>Romance Scam Recovery</p>
                                    </div> 
                                    <div className='pl-3 mt-2 pr-1'>
                                        <p>
                                        Supporting Those Affected by Romance Scams, we're committed to helping you overcome the distress caused by these deceitful actions. With our focused experts at Retrieve digital crypto, we're determined to rebuild your confidence and guide you through the recovery of your lost assets.
                                        </p>
                                    </div> 
                                    <div className='mt-5 Abt_left pb-2 ml-3'>
                                       <a href="/Romance"><button className='About__btnx'>See More <i class="ri-arrow-right-line "></i></button></a> 
                                    </div> 
                            </div>  

                            <div className="max-w-md mx-auto overflow-hidden shadow-lg relative bg-[#ecebeb] rounded-lg border-4 border-transparent transition-transform ">
                                <div className="relative overflow-hidden aspect-w-16 aspect-h-9 transition-transform hover:scale-105">
                                     <img
                                        src="https://imginn.live/wp-content/uploads/2023/09/Teen-girl-smiling-while-looking-at-social-media-likes-scaled-1.jpeg"
                                        alt=""
                                        className="object-cover cursor-pointer"
                                    />
                                </div>
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center transition-transform hover:scale-125">
                                    
                                </div>
                                    <div className="text-start mt-4 pl-3">
                                        <p className='font-bold text-[1.2rem]'>Social Media Hack/Recovery</p>
                                    </div> 
                                    <div className='pl-3 mt-2 pr-1'>
                                        <p>
                                        Empowering Your Digital Connections: Social accounts serve as vital bridges to numerous accomplishments and connections. We are fully dedicated to ensuring the swift recovery of your account. Place your trust in our expertise and commitment as we work diligently to reinstate your access."
                                        </p>
                                    </div> 
                                    <div className='mt-5 Abt_left pb-2 ml-3'>
                                      <a href="/SocialMedia">
                                        <button className='About__btnx'>See More <i class="ri-arrow-right-line "></i></button>
                                        </a>  
                                    </div> 
                            </div>  

                            <div className="max-w-md mx-auto overflow-hidden shadow-lg relative bg-[#ecebeb] rounded-lg border-4 border-transparent transition-transform ">
                                <div className="relative overflow-hidden aspect-w-16 aspect-h-9 transition-transform hover:scale-105">
                                     <img
                                        src="https://cdn.shopify.com/s/files/1/0737/8957/1360/files/R_480x480.jpg?v=1696924194"
                                        alt=""
                                        className="object-cover cursor-pointer"
                                    />
                                </div>
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center transition-transform hover:scale-125">
                                    
                                </div>
                                    <div className="text-start mt-4 pl-3">
                                        <p className='font-bold text-[1.2rem]'>Blackmail / Extortion Help</p>
                                    </div> 
                                    <div className='pl-3 mt-2 pr-1'>
                                        <p>
                                        Empowering and Advocating for Victims of Online Blackmail, Sextortion, and Threats. Our mission is to provide unwavering support and assistance to individuals who have encountered distressing experiences. We stand by their side, offering expert guidance to those affected by online threats and malicious intent.
                                        </p>
                                    </div> 
                                    <div className='mt-5 Abt_left pb-2 ml-3'>
                                       <a href="/Blackmail"><button className='About__btnx'>See More <i class="ri-arrow-right-line "></i></button></a> 
                                    </div> 
                            </div>  

                            <div className="max-w-md mx-auto overflow-hidden shadow-lg relative bg-[#ecebeb] rounded-lg border-4 border-transparent transition-transform ">
                                <div className="relative overflow-hidden aspect-w-16 aspect-h-9 transition-transform hover:scale-105">
                                     <img
                                        src="https://cellularnews.com/wp-content/uploads/2023/07/can-you-check-to-see-if-your-phone-is-hacked-1688826747.jpg"
                                        alt=""
                                        className="object-cover cursor-pointer"
                                    />
                                </div>
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center transition-transform hover:scale-125">
                                    
                                </div>
                                    <div className="text-start mt-4 pl-3">
                                        <p className='font-bold text-[1.2rem]'>Phone & Device Hack/Unlock</p>
                                    </div> 
                                    <div className='pl-3 mt-2 pr-1'>
                                        <p>
                                        Unlock the potential to regain access to your locked accounts with our tailored tools. Experience the convenience of our solutions designed to overcome account lockouts effortlessly. At Retrieve digital crypto, we specialize in swift account recovery, providing you with the means to regain control and peace of mind for your digital assets.
                                        </p>
                                    </div> 
                                    <div className='mt-5 Abt_left pb-2 ml-3'>
                                      <a href="/Phone"><button className='About__btnx'>See More <i class="ri-arrow-right-line "></i></button></a>  
                                    </div> 
                            </div>  

                            <div className="max-w-md mx-auto overflow-hidden shadow-lg relative bg-[#ecebeb] rounded-lg border-4 border-transparent transition-transform ">
                                <div className="relative overflow-hidden aspect-w-16 aspect-h-9 transition-transform hover:scale-105">
                                     <img
                                        src="https://www.brasilfashionnews.com.br/wp-content/uploads/2023/07/vista-traseira-do-programador-trabalhando-a-noite-toda.jpg"
                                        alt=""
                                        className="object-cover cursor-pointer"
                                    />
                                </div>
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center transition-transform hover:scale-125">
                                    
                                </div>
                                    <div className="text-start mt-4 pl-3">
                                        <p className='font-bold text-[1.2rem]'>Account Activation</p>
                                    </div> 
                                    <div className='pl-3 mt-2 pr-1'>
                                        <p>
                                        At Retrieve digital crypto, we specialize in swift account recovery, providing tailored tools and expert solutions to effortlessly overcome account lockouts, unlocking the potential for you to regain control and peace of mind for your digital assets.
                                        </p>
                                    </div> 
                                    <div className='mt-5 Abt_left pb-2 ml-3'>
                                      <a href="/Account">
                                        <button className='About__btnx'>See More <i class="ri-arrow-right-line "></i></button>
                                    </a>  
                                    </div> 
                            </div>  

                    </div>
                </div>
            </div>

            <div className='bg-[#e7e7e7]'>

            
            <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                        <div className='pt-5 ml-3'>
                            <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className='top__b'>Why Choose Us</span></p>
                        </div>
                <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[3%]'>
                    <div className='b-green-600 h-fit'>
                            <div>
                                <img src="img2.png" alt="" />
                            </div>
                    </div>
                    <div className='bg-geen-600 h-fit'>
                    <motion.div className="bg-[geen] h-fit shadow-lg pl-2 pr-2 pb-2 pt-1 rounded-lg  right__border bg-white"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.4 }}
                    transition={{ delay: 0.2, duration: 0.4 }}
                    variants={{
                        hidden: { opacity: 0, x: 50 },
                        visible: { opacity: 1, x: 0 },
                    }}>
                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.3, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                        <p>
                            With Retrieve digital crypto at the helm and a dedicated team by your side, Retrieve digital crypto is your unwavering partner 
                            in regaining what's rightfully yours. Our commitment to excellence, paired with our team's profound grasp of recovery 
                            intricacies, sets us apart. Here's why opting for us is the optimal choice for you and your assets:
                        </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.4, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#a5c638]'/>
                            </div>
                            <p className='pt-2 pl-4'>
                                <span className='font-bold'>Experience Matters:</span> With
                                 years of expertise, we're adept at navigating complex recovery scenarios.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.5, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#a5c638]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Compassionate Support:</span> Our
                                 empathetic team stands by you, offering both expertise and understanding.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.6, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#a5c638]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Proven Success:</span> Our track record showcases our ability to deliver tangible results.
                            </p>
                        </motion.div>

                        <motion.div className='flex'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.7, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                            <div className='pt-5'>
                            <BsCheck2All className='text-[#a5c638]'/>
                            </div>
                            <p className='pt-2 pl-5'>
                                <span className='font-bold'>Your Satisfaction Guaranteed:</span>  Your
                                peace of mind is our ultimate goal – choose us for a dedicated partner in recovery..
                            </p>
                        </motion.div>

                        <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circle/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlex/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlexx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Success</p>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                    </div>
                </div>
            </div>
                <div className='mx-auto overflow-hidden  md:max-w-7xl pb-10'>
                    <div className='pt-5 ml-3'>
                        <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className='top__b'>Testimonies</span></p>
                    </div>
                    <Review/>
                </div>

                <div className='mx-auto overflow-hidden  md:max-w-7xl pb-10'>
                    <div className='pt-5 mr-3 justify-end flex'>
                        <p className='xl:text-[2.1rem] text-[1.8rem] font-bold'><span className='top__b'>Frequently Ask Questions</span></p>
                    </div>





                    <div className='mx-auto overflow-hidden md:max-w-7xl'>
                        <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2  xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-4 pb-10 pt-[3%]'>
                            <div className='bg-green-00 h-fit'>
                                <Accordion
                                    title="100% Refund Ploicy"
                                    content="Retrieve digital crypto is our unwavering commitment to delivering superior results and 
                                    meeting the high expectations of our valued clients. While we have full confidence in our ability 
                                    to accomplish the given tasks, we acknowledge that unforeseen circumstances may occasionally arise, 
                                    potentially impacting our ability to honor our commitments. In such cases, if we find ourselves 
                                    unable to fulfill a task, we stand by our commitment to customer satisfaction by offering a refund, 
                                    ensuring trust in our brand
                                    At the heart of our operations lie principles of transparency and fairness in all our dealings. Our 
                                    terms and conditions are thoughtfully designed to provide clarity and protection for both our clients 
                                    and our team. Our dedicated focus remains on delivering exceptional service and value to our clients. 
                                    Continuously, we strive to improve our processes, consistently surpassing expectations.
                                    "
                                />
                                <Accordion
                                   title="How does your recovery firm operate?"
                                    content="Our firm, specializing in scam recovery, is steadfast in its commitment to aiding victims of 
                                    fraudulent schemes throughout the intricate process of reclaiming their lost funds. Employing a 
                                    multi-faceted approach, we merge specialized expertise, cutting-edge technology, and legal resources 
                                    to offer the utmost support to our clients.
                                    Central to our operations is a team comprising seasoned professionals with a niche focus on the complex 
                                    realm of scam recovery. These adept experts harness a blend of investigative methodologies and financial
                                     expertise to meticulously unravel the intricate web of transactions associated with the scam. 
                                     Through exhaustive tracking of the financial trail, we meticulously pinpoint and segregate the assets 
                                     rightfully belonging to you.
                                    "
                                />
                            </div>
                            <div className='bg-green600 h-fit'>
                                <Accordion 
                                    title="Can I Trust This Firm?" 
                                    content="We acknowledge the pivotal importance of trust and authority when it comes to our clients 
                                    feeling secure and confident in sharing sensitive financial information with our team. We are fully 
                                    aware that the decision to entrust us with such critical information necessitates careful 
                                    consideration, a responsibility we take with the utmost seriousness.
                                    We firmly believe that trust is not simply given, but earned through a continuous display of 
                                    consistent and dependable performance. Our commitment lies in surpassing our clients' expectations 
                                    in every facet of our work, striving to foster an environment of reliability and excellence."
                                    
                                    />
                                <Accordion 
                                    title="How Long dose the process take?" 
                                    content="The process of recovering lost Cryptocurrency spans a variable timeline, fluctuating between 
                                    durations as short as one hour and potentially extending to two months or more. This duration may seem 
                                    extensive due to our comprehensive initial search conducted within the first week of onboarding a 
                                    client. After this exhaustive search, we retain the encrypted private backup and employ password 
                                    guesses to perpetuate testing, identifying new recovery vectors, and enhancing our hardware 
                                    capabilities.
                                    Respecting the privacy and security of our clients, we maintain client information unless expressly 
                                    requested for deletion. Our commitment to safeguarding client privacy remains unwavering, adhering 
                                    to strict protocols in data management
                                    " 
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <section class="hero">
                    <div class="hero-content">
                        <h2 className="text-center">Need our professional services?</h2>
                        <p className="text-center">Contact us now to get started!</p>
                        <Link to="/Contact" class="contact-btn">Contact Us</Link>
                    </div>
                </section>
            </div>

            <div>
                <Footer/>
            </div>
        </div>
    </>
  )
}

export default Home